import { defineMessages } from '@/lib/i18n';

const m = defineMessages({
  limit_1: 'SDS ごとに 1 つの添付ファイル',
  limit_10: 'SDS あたり 10 個の添付ファイル',
  limit_50: 'SDS あたり 50 個の添付ファイル',
});

export class AttachmentLimitId {
  private static readonly _values: AttachmentLimitId[] = [];

  static readonly LIMIT_1 = new AttachmentLimitId(1, m.limit_1);
  static readonly LIMIT_10 = new AttachmentLimitId(10, m.limit_10);
  static readonly LIMIT_50 = new AttachmentLimitId(50, m.limit_50);

  private constructor(readonly value: number, readonly text: string) {
    AttachmentLimitId._values.push(this);
  }

  static fromAttachmentLimitId(value: number): AttachmentLimitId {
    const res = AttachmentLimitId._values.find((x) => x.value === value);
    if (res) {
      return res;
    } else {
      return AttachmentLimitId.LIMIT_1;
    }
  }

  static values(): AttachmentLimitId[] {
    return AttachmentLimitId._values;
  }
}
