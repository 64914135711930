const loadGTM = (gtmId: string): void => {
  if (!gtmId) {
    console.error('GTM ID is required');
    return;
  }

  // Create and append the GTM script tag
  const script: HTMLScriptElement = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
  document.head.appendChild(script);

  // Create and append the GTM iframe for noscript fallback
  const iframe: HTMLIFrameElement = document.createElement('iframe');
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
  iframe.style.display = 'none';
  iframe.style.width = '0';
  iframe.style.height = '0';
  iframe.setAttribute('aria-hidden', 'true');
  iframe.setAttribute('title', 'Google Tag Manager');
  document.body.appendChild(iframe);
};

if (process.env.NODE_ENV === 'production') {
  loadGTM('GTM-5KQJT43');
}
